@font-face {
  font-family: 'Hind Siliguri';
  src: url('../ttf/Hind_Siliguri/HindSiliguri-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'Hind Siliguri';
  src: url('../ttf/Hind_Siliguri/HindSiliguri-Bold.ttf');
  font-weight: bold;
}

@import './variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
