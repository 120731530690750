@font-face {
    font-family: 'Sora';
    src: url('../ttf/Sora/Sora-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('../ttf/Sora/Sora-Bold.ttf');
    font-weight: bold;
}

html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

body {
    user-select: none;
    cursor: default;
}

.alturacompleta {
    height: 100vh;
}

.icochico {
    width: 24px;
    text-align: center;
    display: inline-block;
}

.enlacespan {
    text-decoration: underline;
}

.cursorpointer {
    cursor: pointer;
}

.fuentetitulo {
    font-family: 'Sora', sans-serif;
}