@import '../css/variables';

.botonModulo {
    width: 45px;
    height: 45px;
    margin-bottom: 5px;
    color: $light;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.botonModulo.activo {
    background-color: $light;
    color: $primary;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

.botonModulo:hover {
    background-color: $gray-900;
}

.botonModulo.activo:hover {
    background-color: $gray-400;
}

.tooltipModulo {
    font-size: 1em;
    --bs-tooltip-bg: var(--bs-dark);
    --bs-tooltip-opacity: 1;
    --bs-tooltip-color: var(--bs-white);
}
.tooltip-inner {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
    padding-left: 15px;
    padding-right: 15px;
}