$font-family-sans-serif: 'Hind Siliguri', sans-serif;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #ecf0f1;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #b4bcc2;
$gray-600: #95a5a6;
$gray-700: #7b8a8b;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #2c3e50;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #e74c3c;
$orange: #fd7e14;
$yellow: #f39c12;
$green: #18bc9c;
$teal: #20c997;
$cyan: #3498db;

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-200;
$dark: $gray-800;

$min-contrast-ratio: 2.05;
