.fondomodal {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(96, 96, 96, 0.5);
    backdrop-filter: blur(5px) grayscale(50%);
}

.alineacionmodal {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contenidomodal {
    opacity: 1;
}

.sombramodal {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25)
}
