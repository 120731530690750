.pantallacargando {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    /*background-color: rgba(255, 255, 255, 0.5);*/
    backdrop-filter: blur(2px) grayscale(50%);
}

.iconocargando {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}
